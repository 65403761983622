import { datadogLogs } from '@datadog/browser-logs'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import https from 'https'
import winston from 'winston'
import nodeEnv from '../helpers/nodeEnv'
import { GetNuxtErrorFromAxiosError } from '../helpers/error'
import { NuxtError } from '@nuxt/types'

export interface HttpServiceConfig {
  baseURL: string
  timeout?: number
  logger?: winston.Logger | typeof datadogLogs.logger
  loggerConsole?: Console
  name: string
  proxyBaseURL?: string
  rejectUnauthorized?: boolean
}

/**
 * ### HttpService
 * Is base of Http Service with simple log info and error on interceptors
 */
class HttpService {
  baseURL: string

  client: AxiosInstance

  httpProxyService?: HttpService

  logger?: winston.Logger | typeof datadogLogs.logger

  loggerConsole?: Console

  name?: string = 'Default HttpService'

  proxyBaseURL?: string

  rejectUnauthorized: boolean = nodeEnv.isProduction

  timeout: number = 1000 * 30

  constructor(config: HttpServiceConfig) {
    if (!config.baseURL) {
      throw Error('`HttpServiceConfig.baseURL` is required')
    }
    this.baseURL = config.baseURL
    this.logger = config.logger
    this.loggerConsole = config.loggerConsole
    this.name = config.name
    this.proxyBaseURL = config.proxyBaseURL

    if (config.rejectUnauthorized !== undefined) {
      this.rejectUnauthorized = config.rejectUnauthorized
    }

    if (config.timeout !== undefined) {
      this.timeout = config.timeout
    }

    // create proxy service
    this.httpProxyService = this.instanceProxy()

    // create client with interceptor
    this.client = this.create(true)
  }

  /**
   * ### Add Interceptors on instance client
   * Handler to use interceptor on client instance
   */
  async useInterceptors(client: AxiosInstance) {
    client.interceptors.request.use(
      async config => await this.handlerRequestConfig(config),
      error => this.handlerRequestError(error)
    )
    client.interceptors.response.use(
      async response => await this.handlerResponseSuccess(response),
      error => {
        return this.handlerResponseError(error)
      }
    )
  }

  // interceptors
  handlerRequestConfig = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    this.requestLoggerInfo(config)
    return config
  }

  handlerRequestError = (error: AxiosError): Promise<any> => {
    this.requestLoggerError(error)
    return Promise.reject(error)
  }

  handlerResponseSuccess = async (response: AxiosResponse): Promise<AxiosResponse> => {
    this.responseLoggerInfo(response)
    return response
  }

  handlerResponseError = (error: AxiosError): Promise<any> => {
    this.responseLoggerError(error)
    return Promise.reject(error)
  }

  /**
   * ### Create Client
   * Create new Axios client instance and define `baseUrl` and `timeout` config from `HttpService.config`
   * @returns
   */
  create = (useInterceptors: boolean = false) => {
    this.loggerConsole?.info(
      `> Create Client "${this.name}" ${
        useInterceptors ? 'with' : 'without'
      } interceptors,  base url : ${this.baseURL}`
    )
    const client = axios.create({
      withCredentials: true,
      httpsAgent: this.baseURL.includes('https') ? this.getHttpsAgent() : null,
      httpAgent: this.baseURL.includes('https') ? this.getHttpsAgent() : null,
      baseURL: this.baseURL,
      timeout: this.timeout
    })
    if (useInterceptors) {
      this.useInterceptors(client)
    }
    return client
  }

  /**
   * ### Instant Client
   * Create Instance of `HttpService` for proxy
   * @returns
   */
  instanceProxy() {
    if (!this.proxyBaseURL) {
      return
    }

    return new HttpService({
      baseURL: this.proxyBaseURL,
      logger: this.logger,
      loggerConsole: this.loggerConsole,
      name: `${this.name} [proxy]`
    })
  }

  /**
   * ### Get Https Agent
   * Create new `https.Agent` and Set `HttpService.config.rejectUnauthorized` to `options.rejectUnauthorized`
   * @returns
   */
  getHttpsAgent = () => {
    return new https.Agent({
      rejectUnauthorized: this.rejectUnauthorized
    })
  }

  /**
   * ### Request Logger Info
   * @param config
   * @returns
   */
  requestLoggerInfo = (config: AxiosRequestConfig) => {
    const log = `${this.name} ${this.baseURL}\n  ↗️ request info : ${config?.baseURL}${
      config?.url || ''
    }\n`
    this.loggerConsole?.info(`\x1b[36m ${log} \x1b[0m`)
    this.logger?.info(log)
  }

  /**
   * ### Request Logger Error
   * @param error
   * @returns
   */
  requestLoggerError = (error: AxiosError) => {
    const log = this.getErrorLog('↗️ request error : status code', error)

    this.loggerConsole?.error(`\x1b[31m ${log} \x1b[0m`)
    this.logger?.error(log)
  }

  /**
   * ### Response Logger Info
   * @param response
   * @returns
   */
  responseLoggerInfo = (response: AxiosResponse) => {
    const log = `${this.name} ${this.baseURL}\n  ↘️ response info : status code (${
      response?.status
    }) - ${response?.config?.baseURL}${response?.config?.url || ''}\n`
    this.loggerConsole?.info(`\x1b[32m ${log} \x1b[0m`)
    this.logger?.info(log)
  }

  /**
   * ### Response Logger Error
   * @param error
   * @returns
   */
  responseLoggerError = (error: AxiosError) => {
    const log = this.getErrorLog('↘️ response error : status code', error)

    this.loggerConsole?.error(`\x1b[31m ${log} \x1b[0m`)
    this.logger?.error(log)
  }

  getErrorLog = (label: string, error: AxiosError): string => {
    const customError: AxiosError & NuxtError = { ...error, ...GetNuxtErrorFromAxiosError(error) }

    return `${this.name} ${this.baseURL}\n  ${label} (${customError.statusCode}) - ${
      customError.message
    } ${(customError.response as any)?.message} - ${customError.config?.baseURL || ''}${
      customError.config?.url || ''
    }\n`
  }
}

export default HttpService
