import { get, assign } from 'lodash-es'
import { AxiosInstance, AxiosResponse } from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import type { Form, FormGetResponse } from '@/components/forms/forms.interface'
import type { DeepIndexes, DeepGetterParams } from '~/types/common.interface'

export const state = () => ({
  forms: {} as DeepIndexes<Form>,
  // refaco #enrichedForms - can be direcly use forms
  enrichedForms: {} as DeepIndexes<Form>,
  currentFormId: null,
  seoTags: {}
})

export type FormState = ReturnType<typeof state>

export const getters: GetterTree<FormState, FormState> = {
  getForm:
    state =>
    ({ organization, slug, type }: DeepGetterParams) =>
      state.forms[organization]?.[type]?.[slug],
  getEnrichedForm:
    state =>
    ({ organization, slug, type }: DeepGetterParams) =>
      state.enrichedForms[organization]?.[type]?.[slug],
  getTiers: state => (orgName: string, campaignName: string) =>
    get(state.forms, `${orgName}.${campaignName}.tiers`, []),
  getFormId: state => (orgName: string, campaignName: string) => {
    return get(state.forms, `${orgName}.${campaignName}.id`)
  },
  allowCoupons: state => (formId: string) => get(state.forms, `${formId}.allowCoupons`),
  hasCustomTier: state => (formId: string) => get(state.forms, `${formId}.allowCustomTier`),
  allowOptionalDonation: state => (orgName: string, campaignName: string) =>
    get(state.forms, `${orgName}.${campaignName}.acceptOpenDonation`),
  getSeoTags: state => state.seoTags
}

export const mutations: MutationTree<FormState> = {
  FETCH_FORM(state, [form, { organization, slug, type }]: [Form, DeepGetterParams]) {
    assign(state.forms, {
      [organization]: {
        [type]: {
          [slug]: {
            ...form
          }
        }
      }
    })
  },
  FETCH_ENRICHED_FORM(state, [form, { organization, slug, type }]: [Form, DeepGetterParams]) {
    assign(state.enrichedForms, {
      [organization]: {
        [type]: {
          [slug]: {
            ...form
          }
        }
      }
    })
  },
  SET_SEOTAGS(state, seoTags) {
    state.seoTags = seoTags
  }
}

export const actions: ActionTree<FormState, FormState> = {
  fetchFormIfNeeded({ state, dispatch }, { organization, slug, type }: DeepGetterParams) {
    if (state.forms[organization]?.[type]?.[slug]) {
      return Promise.resolve(state.forms[organization]?.[type]?.[slug])
    }
    return dispatch('fetchForm', { organization, slug, type })
  },
  async fetchProvider({ commit }, slug: string) {
    const urlProvider = `/organizations/${slug}/provider`

    // @ts-expect-error import problem
    const orgaProvider = await (this.$apiClient as AxiosInstance)
      .get(urlProvider)
      .then((response: AxiosResponse) => {
        commit('organizations/SET_PROVIDER', response.data, {
          root: true
        })
        return response.data
      })
    return orgaProvider
  },
  // refacto - 1 : Fetch Form data
  fetchForm({ commit, dispatch }, { organization, slug, type }: DeepGetterParams) {
    const url = `/agg/form?organizationSlug=${organization}&formType=${type}&formSlug=${slug}`

    // @ts-expect-error import problem
    return (this.$apiClient as AxiosInstance)
      .get(url)
      .then((response: AxiosResponse<FormGetResponse>) => {
        // refacto - 2 : save to store
        commit('FETCH_FORM', [response.data.form, { organization, slug, type }])
        commit('organizations/FETCH_ORGANIZATION', response.data.organization, {
          root: true
        })
        dispatch('fetchProvider', organization)
        commit(
          'organizations/FETCH_COMPLIANCE',
          {
            compliance: response.data.compliance,
            slug: response.data.organization?.organizationSlug
          } || {},
          {
            root: true
          }
        )
        commit('SET_SEOTAGS', response.data.seoTags)
        return response.data
      })
      .catch(e => {
        throw { ...e, routeCode: 'GET /forms/agg/form' }
      })
  },
  fetchEnrichedForm({ commit }, { organization, slug, type }: DeepGetterParams) {
    const url = `/organizations/${organization}/forms/${type}/${slug}/enriched`

    // @ts-expect-error import problem
    return (this.$apiClient as AxiosInstance)
      .get(url)
      .then(response => {
        commit('FETCH_ENRICHED_FORM', [response.data, { organization, slug, type }])
        return response.data
      })
      .catch(e => {
        throw e
      })
  },
  // refacto params inline
  postReport(
    context,
    {
      reason,
      userEmail,
      userMessage,
      url
    }: { reason: string; userEmail: string; userMessage: string; url: string }
  ) {
    const abuseData = {
      reason,
      userEmail,
      userMessage,
      url
    }

    // @ts-expect-error import problem
    return (this.$apiClient as AxiosInstance)
      .post('/abuses', abuseData)
      .then(response => response.data)
      .catch(e => {
        console.error('[ERROR][ACTION] cannot `postReport` ', e)
        throw e
      })
  }
}
