import Vue from 'vue'
import { UrlToApiTypes } from '@/helpers/enums'

const metadataMixin = {
  computed: {
    metadata() {
      return {
        organization: this.$route.params.organizationSlug?.replace(/\s/g, '-'),
        slug: this.$route.params.campaignSlug?.replace(/\s/g, '-'),
        type: UrlToApiTypes[this.$route.params.formType],
        url_type: this.$route.params.formType
      }
    }
  }
}

// memory leak ? for be sure metadataMixin is load only one time.
// cf. https://v2.nuxt.com/docs/directory-structure/plugins#global-mixins
if (!Vue.__metadataMixin__) {
  Vue.__metadataMixin__ = true
  Vue.mixin(metadataMixin)
}