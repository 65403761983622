// @ts-ignore
import { assign, isEmpty } from 'lodash-es'
import { AxiosInstance, AxiosResponse } from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { getJwtInfo } from '@/helpers/auth'
import type { Organization } from '../components/organizations/organizations.interface'
import type { UserData } from '../components/users/users.interface'

export const FETCH_ME = 'FETCH_ME'
export const FETCH_USER_ORGANIZATIONS = 'FETCH_USER_ORGANIZATIONS'
export const SELECT_USER_ORGANIZATION = 'SELECT_USER_ORGANIZATION'
export const REVOKE_USER = 'REVOKE_USER'
export const SET_IS_CONNECTED = 'SET_IS_CONNECTED'

export const state = () => ({
  profile: {
    // ...user profile
  } as UserData,
  organizations: [
    // ...user organizations
  ] as Organization[],
  selectedOrg: {
    // ...selected organization
  } as Organization,
  isLoading: false
})

export type UserState = ReturnType<typeof state>

export const getters: GetterTree<UserState, UserState> = {
  getMe: state => () => state.profile,
  getUserOrganizations: state => () => state.organizations,
  getUserSelectedOrg: state => () => state.selectedOrg,
  isLoading: state => () => state.isLoading
}

export const mutations: MutationTree<UserState> = {
  [FETCH_ME](state, user: UserData) {
    assign(state, {
      profile: {
        ...user
      }
    })
  },
  [SELECT_USER_ORGANIZATION](state, organization: Organization | object = {}) {
    assign(state, {
      selectedOrg: {
        ...organization
      }
    })
  },
  [REVOKE_USER](state) {
    assign(state, {
      profile: {},
      selectedOrg: {},
      organizations: []
    })
  },
  [FETCH_USER_ORGANIZATIONS](state, organizations: Organization[]) {
    assign(state, {
      organizations: [...organizations]
    })
  }
}

export const actions: ActionTree<UserState, UserState> = {
  fetchMeIfNeeded({ state, dispatch }) {
    // if user exist do nothing
    if (!isEmpty(state.profile)) {
      return Promise.resolve()
    }
    // otherwise proceed as usual and fetch the organization
    return dispatch('fetchMe')
  },

  fetchMe({ commit }) {
    // @ts-expect-error import problem
    return (this.$apiClient as AxiosInstance)
      .get('/agg/user')
      .then((response: AxiosResponse) => {
        const { data } = response
        commit(FETCH_ME, data.user)
        commit(FETCH_USER_ORGANIZATIONS, data.organizations)
      })
      .catch(console.error)
  },

  selectUserOrganization({ commit }, organization: Organization) {
    return Promise.resolve(commit(SELECT_USER_ORGANIZATION, organization))
  },

  async disconnect({ dispatch, commit }, metadata) {
    // @ts-expect-error import problem
    return (this.$httpAuth as AxiosInstance)
      .get('/disconnect')
      .catch(error => {
        throw error
      })
      .finally(() => {
        commit(REVOKE_USER)
        if (metadata) {
          dispatch('forms/fetchForm', metadata, { root: true })
        }
      })
  },

  async connect({ dispatch }, [username, password, metadata]) {
    // @ts-expect-error import problem
    const response = await (this.$httpAuth as AxiosInstance).post('/token', { username, password })
    const { access_token } = response.data
    const { isAdmin } = getJwtInfo(access_token)
    // if user has FormAdmin rights, it doesn't mean he's admin of the current form
    // reload the form with the new access token to get this information (hasAdminRights)
    const promises = [dispatch('fetchMe')]
    if (isAdmin) {
      promises.push(dispatch('forms/fetchForm', metadata, { root: true }))
    }
    return Promise.all(promises)
  }
}
