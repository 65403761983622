export default {
  // TODO clean when abTest is archived
  abcdTestFirstStepCV: {
    id: 1262087,
    variations: {
      a: 0,
      b: 1564271,
      c: 1564293,
      d: 1564294,
      e: 1569112
    }
  },
  abcTestModalCV: {
    id: 1254241,
    variations: {
      a: 0,
      b: 1554282,
      c: 1554286,
      d: 1556878,
      e: 1556879
    }
  }
}
