import atob from 'atob'
import { ICookie } from 'cookie-universal'
import { CookieSerializeOptions } from 'cookie'
import {
  accessTokenCookieName,
  orgAdmin as slugOrganizationAdmin,
  formAdmin as slugFormAdmin,
  refreshTokenCookieName
} from '../constants'

type AccessTokenInformations = {
  isExpired: boolean
  userId: string
  isUnverified: boolean
  hasUserId: boolean
  isAdmin: boolean
}

type DecodedAccessToken = {
  jti: string
  cps: string
  uid?: string
  urs?: string | Array<string>
  nbf: number
  exp?: number
  iss: string
  aud: string
  uvf?: string
}

export const getJwtInfo = (accessToken: string): AccessTokenInformations => {
  if (!accessToken) {
    throw new Error('accessToken is undefined')
  }

  if (typeof accessToken !== 'string') {
    throw new Error('accessToken must be a string')
  }

  const encodedPayload: string = accessToken.split('.')[1]
  if (!encodedPayload) {
    throw new Error('Invalid accessToken')
  }

  const accessTokenString = atob(encodedPayload)
  const decodedAccessToken: DecodedAccessToken = JSON.parse(accessTokenString)
  const utcTimestamp: number = Date.now().valueOf() / 1000

  const isExpired: boolean = decodedAccessToken.exp ? decodedAccessToken.exp < utcTimestamp : false

  const isAdmin: boolean =
    decodedAccessToken.urs?.includes(slugOrganizationAdmin) ||
    decodedAccessToken.urs?.includes(slugFormAdmin) ||
    false

  const isUnverified: boolean = decodedAccessToken.uvf === '1'
  const userId: string = decodedAccessToken.uid || ''
  const hasUserId: boolean = !!userId

  return {
    isExpired,
    userId,
    isUnverified,
    hasUserId,
    isAdmin
  }
}

export const isUserConnected = (cookies: ICookie) => {
  const accessToken = cookies.get(accessTokenCookieName)
  if (!accessToken) return
  const { hasUserId, isUnverified } = getJwtInfo(accessToken)
  return hasUserId && !isUnverified
}

/**
 * Get Bearer Authorization from existing access cookie
 */
export const getBearerFromCookies = (cookies: ICookie) => {
  const accessToken = cookies.get(accessTokenCookieName)
  if (!accessToken) return
  const { isExpired } = getJwtInfo(accessToken)
  if (!isExpired) {
    return `Bearer ${accessToken}`
  }
}

/**
 * Return correct cookie domain property
 * if azure domain (feature branch, staging..) return full host
 * if helloasso, remove www so cookie is in root domain scope
 * @returns
 */
const getCookieDomain = () => {
  const regex = /https:\/\/|http:\/\/|www\.|www/g
  return process.env.NUXT_ENV_BASE_URL
    ? `.${process.env.NUXT_ENV_BASE_URL?.replace(regex, '')}`
    : '.com'
}
