<template>
  <div id="Layout-Widget" class="Campaign">
    <ha-toaster ref="notifications" />
    <nuxt />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { HaToaster } from '@ha/components'
import { accessTokenCookieName } from '@/constants'

export default {
  name: 'LayoutWidget',
  components: {
    HaToaster
  },
  provide() {
    return {
      $notifications: {
        push: this.pushToast
      },
      isWidget: true
    }
  },
  computed: {
    ...mapGetters('user', ['getMe', 'getUserOrganizations'])
  },
  created() {
    const token = this.$cookies.get(accessTokenCookieName)
    if (!token) {
      this.$store.commit('user/REVOKE_USER')
    }
  },
  methods: {
    pushToast(options, error) {
      this.$refs.notifications.push(options)
      if (error) {
        console.error(error)
      }
    }
  },
  head: {
    bodyAttrs: {
      class: 'widget'
    }
  }
}
</script>
<style lang="scss">
body.widget {
  background: transparent;
}
</style>
