// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/illustrations/illustration-abyss.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Error{-webkit-box-orient:vertical;-webkit-box-direction:normal;background-color:var(--ha-color-white);display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}@media(min-width:900px){.Error{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}}.Error--Wrapper{padding-top:4rem;position:relative;width:100%}@media(min-width:900px){.Error--Wrapper{padding-bottom:8rem;padding-top:8rem}}.Error--Title{font-size:3rem;line-height:1.25;margin-bottom:1.5rem}.Error--Subtitle{color:var(--ha-color-text-light);margin-bottom:2rem}.Error--Code{color:var(--ha-color-text-lightest);font-weight:600}.Error--Image{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0;background-size:cover;height:100vw;margin-top:4rem;-webkit-transform:rotate(90deg);transform:rotate(90deg);width:100%}@media(min-width:900px){.Error--Image{height:100%;margin-top:0;position:absolute;right:0;top:0;-webkit-transform:rotate(0);transform:rotate(0);width:50%}}.Error--Buttons{margin-left:-.5rem;margin-right:-.5rem}.Error--Buttons a,.Error--Buttons button{margin:.5rem}.Error p{line-height:1.75;margin-bottom:1rem}.Error p:last-of-type{margin-bottom:2rem}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
