export const defaultColor = '#4B3FCF'

export const accessTokenCookieName = 'tm5-HelloAsso'
export const refreshTokenCookieName = 'rm5-HelloAsso'

export const orgAdmin = 'OrganizationAdmin'
export const formAdmin = 'FormAdmin'

export const cartLifespanMinutes = 15

export const facebookAppId = 164611196937215

export const individualPercentageReduction = 66

// The reduction percentage for individuals to Coluche Law organisms is at 75%
export const colucheLawIndividualPercentageReductionUnderCeiling = 75

// The Coluche Law ceiling in 2019 is at 54600cents (100000cents exceptionally for the years 2020 to 2023)
export const colucheLawIndividualCeiling = 100000

// The reduction percentage for enterprises is at 60%
export const enterprisePercentageReduction = 60
