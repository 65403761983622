import { AxiosInstance } from 'axios'
import HttpApiService from '../services/httpApiService'
import HttpAuthService from '../services/httpAuthService'
import nodeEnv from './nodeEnv'

interface AxiosClientConfig {
  name: string
  authBaseURL: string
  authProxyBaseURL: string
  apiBaseURL: string
  apiProxyBaseURL: string
  logger: any
  cookies: any
}

const axiosClients = ({
  name,
  authBaseURL,
  authProxyBaseURL,
  apiBaseURL,
  apiProxyBaseURL,
  logger,
  cookies
}: AxiosClientConfig): {
  httpAuth: AxiosInstance | undefined
  apiClient: AxiosInstance
} => {
  // Show debug in console only for development node env
  const loggerConsole = nodeEnv.isDevelopment ? console : undefined

  // Http Auth
  const httpAuthService = new HttpAuthService({
    baseURL: authBaseURL,
    proxyBaseURL: authProxyBaseURL,
    cookies,
    logger: logger,
    loggerConsole,
    name: `${name} Http Auth`
  })

  // Http Api
  const httpApiService = new HttpApiService(
    {
      baseURL: apiBaseURL,
      proxyBaseURL: apiProxyBaseURL,
      logger: logger,
      loggerConsole,
      name: `${name} Http Api`
    },
    httpAuthService
  )

  return {
    /* Nuxt app will always use the auth proxy (in serverMiddleware) instead of oauth api directly to obfuscate it */
    httpAuth: httpAuthService.httpProxyService?.client,
    apiClient: httpApiService.client
  }
}

export default axiosClients
