import { computed, inject } from 'vue'
import { enum as enums } from '@ha/helpers'
import useStoreData from '@/composables/useStoreData'
import useRouteParams from '@/composables/useRouteParams'
import { useContext } from '@nuxtjs/composition-api'

export default () => {
  const { form } = useStoreData()
  const { formType } = useRouteParams()
  const { $config } = useContext()

  const isCrowdfunding = computed(() => form.value?.formType === enums.FormType.CROWDFUNDING)
  const isEvent = computed(() => form.value?.formType === enums.FormType.EVENT)
  const isMembership = computed(() => form.value?.formType === enums.FormType.MEMBERSHIP)
  const isShop = computed(() => form.value?.formType === enums.FormType.SHOP)
  const isSale = computed(() => form.value?.formType === enums.FormType.PAYMENTFORM)
  const isDonation = computed(() => form.value?.formType === enums.FormType.DONATION)
  // there is no form - and so no form.formType - for checkout bc no campaignSlug, however there is a formType from route params
  const isCheckout = computed(
    () => !form.value?.formType && formType.value === enums.FormType.CHECKOUT
  )

  const isWidget = inject('isWidget')
  
  const isQuickWinForm = computed(() => !isWidget && $config.QUICK_WINS_FORM_ENABLED && (isShop.value || isMembership.value || isEvent.value))

  return {
    isCrowdfunding,
    isEvent,
    isMembership,
    isShop,
    isSale,
    isDonation,
    isCheckout,
    isQuickWinForm
  }
}
