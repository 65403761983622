<template>
  <div class="AdminSettings">
    <ul class="AdminSettingsList">
      <li v-if="urls.edition" class="AdminSettingsListItem">
        <a
          :href="urls.edition"
          rel="noopener noreferrer"
          target="_blank"
          class="AdminSettingsListItem--Link"
          :data-ux="`Forms_AdminSettings_Update`"
        >
          <fa icon="pencil-alt" class="AdminSettingsListItem--Icon" aria-hidden="true" />
          <span class="AdminSettingsListItem--Title-Mobile">
            {{ $t('administration.updateShort') }}
          </span>
          <span class="AdminSettingsListItem--Title-Desktop">
            {{ $t('administration.update') }}
          </span>
        </a>
      </li>
      <li v-if="urls.diffusion" class="AdminSettingsListItem">
        <a
          :href="urls.diffusion"
          rel="noopener noreferrer"
          target="_blank"
          class="AdminSettingsListItem--Link"
          :data-ux="`Forms_AdminSettings_Share`"
        >
          <fa icon="share-alt" class="AdminSettingsListItem--Icon" aria-hidden="true" />
          <span class="AdminSettingsListItem--Title-Mobile">
            {{ $t('administration.shareShort') }}
          </span>
          <span class="AdminSettingsListItem--Title-Desktop">
            {{ $t('administration.share') }}
          </span>
        </a>
      </li>
      <li v-if="urls.statistiques" class="AdminSettingsListItem">
        <a
          :href="urls.statistiques"
          rel="noopener noreferrer"
          target="_blank"
          class="AdminSettingsListItem--Link"
          :data-ux="`Forms_AdminSettings_Stats`"
        >
          <fa icon="chart-bar" class="AdminSettingsListItem--Icon" aria-hidden="true" />
          {{ $t('administration.stats') }}
        </a>
      </li>
      <li v-if="urls.admins" class="AdminSettingsListItem">
        <a
          :href="urls.admins"
          rel="noopener noreferrer"
          target="_blank"
          class="AdminSettingsListItem--Link"
          :data-ux="`Forms_AdminSettings_Admins`"
        >
          <fa icon="cog" class="AdminSettingsListItem--Icon" aria-hidden="true" />
          {{ $t('administration.admin') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { enum as enums } from '@ha/helpers'

export default {
  name: 'AdminSettings',
  data() {
    return {
      originAdmin: process.env.NUXT_ENV_BO_URL,
      legacyAdmin: process.env.NUXT_ENV_OLD_BO_URL
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    formTypes() {
      return enums.FormType
    },
    urls() {
      const formType =
        this.form.formType === this.formTypes.PAYMENTFORM ? 'ventes' : this.metadata.url_type

      return {
        edition: `${this.originAdmin}/${this.metadata.organization}/${formType}/${this.metadata.slug}/edition/1`,
        diffusion: `${this.originAdmin}/${this.metadata.organization}/${formType}/${this.metadata.slug}/diffusion`,
        statistiques: `${this.originAdmin}/${this.metadata.organization}/${formType}/${this.metadata.slug}/statistiques`,
        admins: `${this.originAdmin}/${this.metadata.organization}/${formType}/${this.metadata.slug}/administrateurs`
      }
    }
  }
}
</script>

<style lang="scss">
.AdminSettings {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;

  @include mediaQuery(600) {
    right: $ha-spacing-large;
    bottom: $ha-spacing-large;
    left: auto;
    width: auto;
  }

  &List {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: var(--ha-color-white);
    box-shadow: $ha-box-shadow-middle;

    @include mediaQuery(600) {
      display: block;
      padding: $ha-spacing-tiny 0;
      border-radius: $ha-radius-large;
    }
  }

  &ListItem {
    width: 25%;
    list-style: none;

    @include mediaQuery(600) {
      width: auto;
    }

    &--Link {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: $ha-spacing-medium $ha-spacing-tiny $ha-spacing-small;
      color: var(--ha-color-text-light);
      font-size: $ha-unit * 1.25; // 11px
      text-align: center;
      transition: padding-left 0.15s ease;

      @include mediaQuery(600) {
        display: inline-block;
        padding: $ha-spacing-small $ha-spacing-jumbo $ha-spacing-small $ha-spacing-large;
        font-weight: $ha-font-weight-semibold;
        font-size: $ha-font-size-small;
        text-align: left;
      }

      &:focus {
        outline: none;
      }

      &:hover,
      &:focus {
        color: var(--ha-color-text);
        font-weight: $ha-font-weight-semibold;
        text-decoration: none;
        background-color: var(--ha-color-background);

        .AdminSettingsListItem--Icon {
          color: var(--ha-color-text-light);
        }
      }

      + .AdminSettingsListItem--Link {
        border-left: $ha-border-regular;

        @include mediaQuery(600) {
          border: none;
        }
      }

      .AdminSettingsListItem--Icon {
        margin-bottom: $ha-spacing-mini;
        color: var(--ha-color-text-lightest);
        font-size: $ha-font-size-regular;
        transition: inherit;

        @include mediaQuery(600) {
          margin-right: $ha-spacing-small;
          margin-bottom: 0;
          font-size: inherit;
        }
      }

      .AdminSettingsListItem--Title-Desktop {
        display: none;

        @include mediaQuery(600) {
          display: inline-block;
        }
      }
    }
  }
}
</style>
